<template>
  <div>
  <CCard>
   
  <CCardBody>
    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
      >
         <template #checkType-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'checkType',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Permit Work Check</option>
            <option value="2">Risk Assessment Check</option>
             <option value="3">Daily Toolbox Check</option>
             <option value="4">Safety Barricade Check</option>
             <option value="5">Equipment Check</option>
             <option value="6">Personal Safety Check</option>
             <option value="7">House Keeping Check</option>
             <option value="8">Hot Works Check</option>
             <option value="9">Scaf Folding Check</option>
             <option value="10">Work In Confined Check</option>
             <option value="11">Work At Height Check</option>
              <option value="12">Excavation Works Check</option>
             <option value="13">>Lifting Works Check</option>
             
          </select>
        </template>
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>
    
    
    
       <template #checkType="{item}">
        <td class='font-weight-bold'  v-show="item.checkType==1">Permit Work Check</td>      
        <td class='font-weight-bold' v-show="item.checkType==2">Risk Assessment Check</td>
        <td class='font-weight-bold' v-show="item.checkType==3">Daily Toolbox Check </td>
        <td class='font-weight-bold' v-show="item.checkType==4">Safety Barricade Check</td>
        <td class='font-weight-bold' v-show="item.checkType==5">Equipment Check </td>
         <td class='font-weight-bold' v-show="item.checkType==6">Personal Safety Check</td>
         <td class='font-weight-bold' v-show="item.checkType==7">House Keeping Check</td>
          <td class='font-weight-bold' v-show="item.checkType==8">Hot Works Check</td>
           <td class='font-weight-bold' v-show="item.checkType==9">Scaf Folding Check</td>
           <td class='font-weight-bold' v-show="item.checkType==10">Work In Confined Check</td>
            <td class='font-weight-bold' v-show="item.checkType==11">Work At Height Check</td>
             <td class='font-weight-bold' v-show="item.checkType==12">Excavation Works Check</td>
              <td class='font-weight-bold' v-show="item.checkType==13">Lifting Works Check</td>

      </template> 
       <template #nonCompliances="{item}">
        <td class='font-weight-bold'
          v-if="item.nonCompliances"
        >{{ item.nonCompliances }}</td>
        <td v-else>--</td>
      </template>
       <!-- <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                   <CDropdownItem :to="{name: 'Job Task', params: { jobTaskId: item. jobTaskId, mode: 'view'}}">View</CDropdownItem> 

                </CDropdown> 

        
        </td>
      </template> -->
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>
<CCardFooter align="right">
         
            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
  
    { key: "checkType",label: "CheckT ype", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "nonCompliances",label: "Non Compliances", _classes: 'font-weight-bold',  _style: "min-width:200px" },
    { key: "checkTypeValue",label: "Check Type Value", _classes: 'font-weight-bold',  _style: "min-width:200px" },
//   { key: "show_details",
//     label: "",
//     _style: "width:1%",
//     sorter: false,
//     filter: false,
//   },
  
];

export default {
  name: "Inspection",
  
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getLocations() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/safety/inspections/checks/"+this.$route.params.inspectionId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
     cancel() {
  
     this.$router.push({ name: "Inspections",params: { jobId: this.$route.params.jobId }});
    },
  },
  mounted() {
    this.getLocations();
  },
};
</script>